import React from 'react';
import { AutoColumn } from 'components/Column';
import Row, { RowBetween } from 'components/Row';
import styled from 'styled-components';
import { TYPE } from 'theme';
import { isMobile } from 'react-device-detect';

const Wrapper = styled.div`
  padding-top: 120px;
  padding-bottom: 200px;
  width: 1220px;
  margin: 0 auto;

  ${({ theme }) => theme.mediaWidth.upToSmall`
  width: 100vw;
  padding: 1rem;
  `}

  .title {
    ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 20px;
    `}
  }

  .titleDesc {
    width: 862px;
    margin: 0 auto;
    margin-top: 20px;
    position: relative;
    z-index: 1;
    ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    > div {
        font-size: 12px;
    }
    `}
  }

  .titleP {
    margin-top: 140px;
    font-weight: 800;

    ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 50px;
    > div {
    font-size: 18px !important;
   }
   
    `}
  }

  .ecotext {
    width: 949px;
    margin: 0 auto;
    ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    padding: 0 1rem;
    margin-top: 28px !important;
    > div {
        font-size: 12px;
    }
    `};
  }
`;

const ColorTitle = styled.span`
  font-size: inherit;
  background: linear-gradient(180deg, #61e882 0%, #00dacc 44%, #20afeb 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const Solutions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 60px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
  flex-direction: column;
  margin-top: 28px;
  `}
`;

const Solution = styled.div`
  width: 376px;
  height: 340px;
  padding: 42px 28px;
  border-radius: 16px;
  border: 2px solid rgba(255, 255, 255, 0.16);
  ${({ theme }) => theme.mediaWidth.upToSmall`
  width: ;
  margin-bottom: 20px;
  padding: 32px 20px;
  width: 282px;

  height: 248px;

  .title {
    font-size: 16px;
    font-weight: bold;
  }
  .desc {
    font-size: 12px;
    font-weight: 500;
  }

  .descWrapper {
    margin-top: 10px !important;
    grid-row-gap: 10px;
  }
  `}
`;

const SolutionImg = styled.img`
  width: 62px;
  height: 62px;
  flex-shrink: 0;
`;

const Missions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 60px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
  `}
`;

const Mission = styled.div`
  position: relative;
  width: 376px;
  height: 142px;
  border: 2px solid #868686;
  border-radius: 12px;
  padding: 42px 26px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 282px;
    height: 106px;
  margin-bottom: 20px;
  height: unset;
  `}
`;

const MissionImg = styled.img`
  position: absolute;
  right: 10px;
  bottom: 0;
  width: 62px;
  height: 54px;
`;

const Oppos = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  margin-top: 80px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  flex-direction: column;
  width: 282px;
  
  margin: 0 auto;
  margin-top: 32px;
  `}
`;

const LeftOppo = styled(AutoColumn)`
  padding-right: 30px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
      grid-row-gap: 20px !important;
      padding-right: 0px;
  `}
`;

const Oppo = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;

  &.right {
    ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 20px;
    `}
  }
`;

const OppoImg = styled.img`
  width: 62px;
  height: 62px;
  margin-right: 20px;
  flex-shrink: 0;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  width: 30px;
  height: 30px;
  margin-right: 8px;
  `}
`;

const Investment = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 auto;
  margin-top: 60px;
  width: 1000px;
  height: 130px;
  > img {
    width: 198px;
    height: 44px;
    margin-right: 108px;

    &:nth-child(3),
    &:last-child {
      margin-right: 0;
    }
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
  margin-top: 20px;
  justify-content: space-between;
  width: 100%;
  padding: 0 1rem;
  padding-bottom: 40px;
  height: 190px;

  > img {
    margin-right: 0;
    width: 121px;
    height: 28px;
  }
  `}
`;

export default function Home() {
  return (
    <Wrapper>
      <Row justify="center">
        <TYPE.main
          textAlign="center"
          fontSize={48}
          fontWeight={800}
          className="title"
          lineHeight="1.6"
        >
          P1X：
          <ColorTitle style={{ fontWeight: '800' }}>
            A Metis Ecosystem
          </ColorTitle>{' '}
          Accelerator
          <br />
          and Project Incubator for the APAC Region
        </TYPE.main>
      </Row>
      {/* <Row justify="center" className="titleDesc">
        <TYPE.desc fontSize={20} textAlign="center">
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation aliquip ex . "
          <MetisTokenImg src={require('assets/img/home/metistoken.png')} />
        </TYPE.desc>
      </Row> */}
      <Row justify="center" className="titleP">
        <TYPE.main textAlign="center" fontSize={32} fontWeight={800}>
          P1X Solutions
        </TYPE.main>
      </Row>
      <Solutions>
        <Solution>
          <RowBetween>
            <TYPE.main
              className="title"
              paddingRight="8px"
              fontSize={22}
              fontWeight={600}
            >
              {`PIX's Resources for Founders`}
            </TYPE.main>
            <SolutionImg src={require('assets/img/home/solution1.png')} />
          </RowBetween>
          <AutoColumn
            className="descWrapper"
            gap="20px"
            style={{ marginTop: '35px' }}
          >
            <TYPE.desc className="desc" fontSize={16}>
              Technical support
            </TYPE.desc>
            <TYPE.desc className="desc" fontSize={16}>
              Global community resources
            </TYPE.desc>
            <TYPE.desc className="desc" fontSize={16}>
              Funding
            </TYPE.desc>
            <TYPE.desc className="desc" fontSize={16}>
              Marketing activities incentives
            </TYPE.desc>
          </AutoColumn>
        </Solution>
        <Solution>
          <RowBetween>
            <TYPE.main
              className="title"
              paddingRight="8px"
              fontSize={22}
              fontWeight={600}
            >
              Incubating Outstanding Projects
            </TYPE.main>
            <SolutionImg src={require('assets/img/home/solution2.png')} />
          </RowBetween>
          <AutoColumn
            className="descWrapper"
            gap="20px"
            style={{ marginTop: '35px' }}
          >
            <TYPE.desc className="desc" fontSize={16}>
              Investment from other funds to expand project acquisition
            </TYPE.desc>
            <TYPE.desc className="desc" fontSize={16}>
              Building in the language of the communities
            </TYPE.desc>
            <TYPE.desc className="desc" fontSize={16}>
              APAC-centric focus
            </TYPE.desc>
          </AutoColumn>
        </Solution>
        <Solution>
          <RowBetween>
            <TYPE.main
              className="title"
              paddingRight="8px"
              fontSize={22}
              fontWeight={600}
            >
              Integrating Quality Projects
            </TYPE.main>
            <SolutionImg src={require('assets/img/home/solution3.png')} />
          </RowBetween>
          <AutoColumn
            className="descWrapper"
            gap="20px"
            style={{ marginTop: '35px' }}
          >
            <TYPE.desc className="desc" fontSize={16}>
              Gaming Studios & Metaverse
            </TYPE.desc>
            <TYPE.desc className="desc" fontSize={16}>
              Mining Infrastructure & program incentives
            </TYPE.desc>
            <TYPE.desc className="desc" fontSize={16}>
              DeFi projects
            </TYPE.desc>
          </AutoColumn>
        </Solution>
      </Solutions>
      <Row justify="center" className="titleP">
        <TYPE.main textAlign="center" fontSize={32} fontWeight={800}>
          Portfolio Company
        </TYPE.main>
      </Row>
      <AutoColumn className="ecotext" gap="40px" style={{ marginTop: '40px' }}>
        <TYPE.desc lineHeight="1.6" fontSize={20} textAlign="center">
          In alignment with the{' '}
          <a
            style={{
              color: '#00dacc',
            }}
            href="https://metisdao.medium.com/metisdao-foundation-2023-strategy-ddced0a1ef22"
            target="_blank"
            rel="noreferrer"
          >
            <ColorTitle style={{ fontWeight: 'bold' }}>
              Metis Foundation’s
            </ColorTitle>
          </a>{' '}
          vision to go fully decentralized, and as a key portfolio company
          within the Metis ecosystem, P1X is closely integrated into the
          Foundation’s dual-layered, DAC-centric (Decentralized Autonomous
          Company) governance structure.
        </TYPE.desc>
        <TYPE.desc lineHeight="1.6" fontSize={20} textAlign="center">
          This structure leverages both token-based and Reputation Power-based
          governance. DACs and Commons play essential roles in the governance
          process, actively contributing to the growth and expansion of the
          entire Metis ecosystem.
        </TYPE.desc>
      </AutoColumn>
      <Row justify="center" className="titleP">
        <TYPE.main textAlign="center" fontSize={32} fontWeight={800}>
          P1X Mission
        </TYPE.main>
      </Row>
      <Row justify="center" marginTop="8px">
        <TYPE.desc
          className="desc"
          fontSize={20}
          mobileFz={12}
          textAlign="center"
        >
          To Accelerate Web3 & Metis Ecosystem in the APAC Region
        </TYPE.desc>
      </Row>
      <Missions>
        <Mission>
          <TYPE.desc lineHeight="1.6" mobileFz={12} fontSize={16}>
            Help Web3 start-ups grow by means of incubation and investment
          </TYPE.desc>
          <MissionImg src={require('assets/img/home/mission1.png')} />
        </Mission>
        <Mission>
          <TYPE.desc lineHeight="1.6" mobileFz={12} fontSize={16}>
            Adopt and develop quality GameFi, Defi, & other blockchain projects
          </TYPE.desc>
          <MissionImg src={require('assets/img/home/mission2.png')} />
        </Mission>
        <Mission>
          <TYPE.desc lineHeight="1.6" mobileFz={12} fontSize={16}>
            Focused on building multinational communities in Vietnam,
            Indonesia,&nbsp; Thailand & the Philippines
          </TYPE.desc>
          <MissionImg src={require('assets/img/home/mission3.png')} />
        </Mission>
      </Missions>
      <Row justify="center" className="titleP">
        <TYPE.main textAlign="center" fontSize={32} fontWeight={800}>
          The APAC Opportunity
        </TYPE.main>
      </Row>
      <Oppos>
        <LeftOppo gap="60px">
          <Oppo>
            <OppoImg src={require('assets/img/home/oppo1.png')} />
            <AutoColumn gap="20px">
              <TYPE.main fontWeight="bold" mobileFz={16} fontSize={26}>
                Gamefi
              </TYPE.main>
              <TYPE.desc lineHeight="1.6" mobileFz={12} fontSize={18}>
                Asia Pacific globally leads the way in play-to-earn games, with{' '}
                <ColorTitle style={{ fontWeight: '800' }}>25%</ColorTitle> of
                Filipinos and{' '}
                <ColorTitle style={{ fontWeight: '800' }}>23%</ColorTitle> of
                Vietnamese engaging in GameFi.
              </TYPE.desc>
            </AutoColumn>
          </Oppo>
          <Oppo>
            <OppoImg src={require('assets/img/home/oppo2.png')} />

            <AutoColumn gap="20px">
              <TYPE.main fontWeight="bold" mobileFz={16} fontSize={26}>
                Highest Crypto Adoption Rate
              </TYPE.main>
              <TYPE.desc lineHeight="1.6" mobileFz={12} fontSize={18}>
                The APAC region currently has the highest cryptocurrency
                adoption rate with Vietnam, Thailand, and India ranked in the
                top 10.
              </TYPE.desc>
            </AutoColumn>
          </Oppo>
        </LeftOppo>
        <Oppo className="right">
          <OppoImg src={require('assets/img/home/oppo3.png')} />

          <AutoColumn gap="20px">
            <TYPE.main fontWeight="bold" mobileFz={16} fontSize={26}>
              Money Bridges
            </TYPE.main>
            <TYPE.desc lineHeight="1.6" mobileFz={12} fontSize={18}>
              The immigrant workforce overseas is crucial to the APAC region,
              creating cross-border opportunities for financial services and
              investments.
            </TYPE.desc>
            {/* <TYPE.desc mobileFz={12} fontSize={18}>
              Countries in the region are among the top 10 in the world for
              cryptocurrency adoption rates
            </TYPE.desc> */}
            {/* <TYPE.desc mobileFz={12} fontSize={18}>
              Asia Pacific globally leads the way in play-to-earn games, with
              Filipinos and Vietnamese boasting the highest engagement rates at
              &nbsp;
              <ColorTitle>25%</ColorTitle> and <ColorTitle>23%</ColorTitle>
            </TYPE.desc> */}
          </AutoColumn>
        </Oppo>
      </Oppos>
      <Row justify="center" className="titleP">
        <TYPE.main textAlign="center" fontSize={32} fontWeight={800}>
          P1X Investments
        </TYPE.main>
      </Row>
      <Investment>
        <img src={require('assets/img/home/parters/p1.png')} alt="" />
        <img src={require('assets/img/home/parters/p2.png')} alt="" />
        <img src={require('assets/img/home/parters/p3.png')} alt="" />
        <img src={require('assets/img/home/parters/p4.png')} alt="" />
        <img src={require('assets/img/home/parters/p5.png')} alt="" />
      </Investment>
    </Wrapper>
  );
}
